@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  border: 0;
  padding: 0;
  font-size: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Hamburg";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/hamburg/HamburgHand-Bold.woff2") format("woff2");
}
/* ------------------------------------- */
.heading-style-1 {
  font-family: "new-spirit-condensed", serif;
  font-size: 3rem;
  font-weight: 600;
  font-style: normal;
  line-height: 100%;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 40em) {
  .heading-style-1 {
    font-size: 3.75rem;
  }
}

.heading-style-2 {
  font-family: "new-spirit-condensed", serif;
  font-size: 2.25rem;
  font-weight: 600;
  font-style: normal;
  line-height: 100%;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 40em) {
  .heading-style-2 {
    font-size: 3rem;
  }
}

.heading-style-3 {
  font-family: "new-spirit-condensed", serif;
  font-size: 2.25rem;
  font-weight: 600;
  font-style: normal;
  line-height: 111%;
  letter-spacing: -0.02em;
}
@media only screen and (min-width: 40em) {
  .heading-style-3 {
    font-size: 2.25rem;
  }
}

.subheading-style-1 {
  font-family: "Hamburg", serif;
  font-size: 1rem;
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.25em;
}
@media only screen and (min-width: 40em) {
  .subheading-style-1 {
    font-size: 1rem;
  }
}

.subheading-style-2 {
  font-family: "Hamburg", serif;
  font-size: 0.75rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25em;
}
@media only screen and (min-width: 40em) {
  .subheading-style-2 {
    font-size: 0.75rem;
  }
}

.paragraph-style-1 {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
}
@media only screen and (min-width: 40em) {
  .paragraph-style-1 {
    font-size: 1.125rem;
    line-height: 155%;
  }
}

.paragraph-style-2 {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
}
@media only screen and (min-width: 40em) {
  .paragraph-style-2 {
    font-size: 1rem;
  }
}

.text-light {
  color: #f8f4df;
}

.text-dark {
  color: #3f5661;
}

.text-primary {
  color: #aa372c;
}

/* ------------------------------------- */
.heading-style-2 + .paragraph-style-1 {
  margin-top: 24px;
}

.heading-style-3 + .paragraph-style-1 {
  margin-top: 24px;
}

.heading-style-3 + .paragraph-style-2 {
  margin-top: 8px;
}

.heading-style-3 + .button-group {
  margin-top: 32px;
}

.paragraph-style-1 + .button-group {
  margin-top: 32px;
}

.paragraph-style-1 + .arrow-btn-group {
  margin-top: 50px;
}

.paragraph-style-2 + .button-group {
  margin-top: 20px;
}

/* ------------------------------------- */
.btn--full-width {
  width: 100%;
}
.btn--solid-cream {
  font-family: "Hamburg", serif;
  font-size: 0.75rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25em;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 16px 20px;
  color: #7c1e39;
  background-color: #f8f4df;
  border: 1px solid;
  transition: 0.2s ease-in-out;
  border-color: #f8f4df;
}
@media only screen and (min-width: 40em) {
  .btn--solid-cream {
    font-size: 0.75rem;
  }
}
.btn--solid-cream:hover {
  color: #09403b;
  background-color: #75C9be;
  border-color: #75C9be;
}
.btn--solid-burgundy {
  font-family: "Hamburg", serif;
  font-size: 0.75rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25em;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 16px 20px;
  color: #f8f4df;
  background-color: #7c1e39;
  border: 1px solid;
  transition: 0.2s ease-in-out;
  border-color: #7c1e39;
}
@media only screen and (min-width: 40em) {
  .btn--solid-burgundy {
    font-size: 0.75rem;
  }
}
.btn--solid-burgundy:hover {
  color: #f8f4df;
  background-color: #aa372c;
  border-color: #aa372c;
}
.btn--hollow-cream {
  font-family: "Hamburg", serif;
  font-size: 0.75rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25em;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 16px 20px;
  color: #f8f4df;
  background-color: transparent;
  border: 1px solid;
  transition: 0.2s ease-in-out;
  border-color: #f8f4df;
}
@media only screen and (min-width: 40em) {
  .btn--hollow-cream {
    font-size: 0.75rem;
  }
}
.btn--hollow-cream:hover {
  color: #09403b;
  background-color: #75C9be;
  border-color: #75C9be;
}
.btn--hollow-burgundy {
  font-family: "Hamburg", serif;
  font-size: 0.75rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25em;
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 16px 20px;
  color: #7c1e39;
  background-color: transparent;
  border: 1px solid;
  transition: 0.2s ease-in-out;
  border-color: #7c1e39;
}
@media only screen and (min-width: 40em) {
  .btn--hollow-burgundy {
    font-size: 0.75rem;
  }
}
.btn--hollow-burgundy:hover {
  color: #f8f4df;
  background-color: #aa372c;
  border-color: #aa372c;
}

.arrow-btn-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.arrow-btn--burgundy {
  font-family: "Hamburg", serif;
  font-size: 0.75rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25em;
  display: flex;
  align-items: center;
  padding: 5px 0;
  color: #7c1e39;
  text-decoration: none;
  transition: 0.2s ease-in-out;
}
@media only screen and (min-width: 40em) {
  .arrow-btn--burgundy {
    font-size: 0.75rem;
  }
}
.arrow-btn--burgundy:hover svg {
  margin-right: 20px;
}
.arrow-btn--burgundy svg {
  width: 18px;
  fill: #7c1e39;
  margin-right: 10px;
  transition: 0.2s ease-in-out;
}
.arrow-btn--burgundy span {
  margin-top: 3px;
}
.arrow-btn + .arrow-btn {
  margin-top: 40px;
}

/* ------------------------------------- */
.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 20px;
  width: 100%;
  max-width: 87.5rem;
  padding: 0 20px;
  margin: 0 auto;
}
@media only screen and (min-width: 40em) {
  .grid {
    column-gap: 40px;
  }
}

body {
  background-color: #f8f4df;
}

img {
  width: 100%;
}

.object-cover {
  object-fit: cover;
  height: 100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.icon-heading {
  z-index: 4;
  position: relative;
}
.icon-heading svg {
  z-index: -1;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 50px;
  fill: #db9279;
  transform: rotate(-15deg);
}
@media only screen and (min-width: 40em) {
  .icon-heading svg {
    top: -16px;
    width: 72px;
  }
}
@media only screen and (min-width: 75em) {
  .icon-heading svg {
    right: -40px;
  }
}

.carousel {
  padding: 60px 0;
  background-color: #9aa99e;
}
.carousel--burgundy {
  background: url("../../images/grain.png"), #7c1e39;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom -20% left;
  background-blend-mode: multiply, normal;
}
@media only screen and (min-width: 40em) {
  .carousel {
    padding: 100px 0;
  }
}
.carousel__intro, .carousel__main {
  grid-column: span 12/span 12;
}
@media only screen and (min-width: 40em) {
  .carousel__intro {
    display: flex;
    justify-content: space-between;
  }
}
.carousel__intro + .carousel__main {
  margin-top: 48px;
}
.carousel__main {
  position: relative;
  z-index: 4;
}
.carousel__decor {
  position: absolute;
  top: 0;
  right: -120px;
  display: none;
  width: 120px;
  transform: translateY(-30%);
}
@media only screen and (min-width: 40em) {
  .carousel__decor {
    display: block;
  }
}
.carousel__heading {
  position: relative;
  color: #172B33;
}
.carousel--burgundy .carousel__heading {
  color: #f8f4df;
}
.carousel__heading + .carousel__controls {
  margin-top: 24px;
}
@media only screen and (min-width: 40em) {
  .carousel__heading + .carousel__controls {
    margin-top: 0;
  }
}
.carousel__controls {
  display: flex;
}
.carousel--burgundy .carousel__controls button:hover svg {
  stroke: #75C9be;
}
.carousel__controls button {
  cursor: pointer;
  width: 45px;
  padding: 0;
  border: none;
  background-color: transparent;
  appearance: none;
}
.carousel__controls button:hover svg {
  stroke: #f8f4df;
}
.carousel__controls button + button {
  margin-left: 24px;
}
.carousel__controls svg {
  width: 100%;
  stroke: #172B33;
  transition: 0.2s ease-in-out;
}
.carousel--burgundy .carousel__controls svg {
  stroke: #f8f4df;
}
.carousel__btn--right svg {
  transform: rotate(180deg);
}
.carousel__image {
  position: relative;
  padding-bottom: 60%;
}
.carousel__image img {
  position: absolute;
  top: 0;
  left: 0;
}
.carousel__image + h3 {
  margin-top: 24px;
}
.carousel__slide h3,
.carousel__slide p {
  color: #172B33;
}
.carousel--burgundy .carousel__slide h3,
.carousel--burgundy .carousel__slide p {
  color: #f8f4df;
}
.carousel__source {
  display: flex;
  color: #172B33;
  margin-top: 24px;
}
.carousel--burgundy .carousel__source {
  color: #f8f4df;
}
.carousel__source-avatar {
  position: relative;
  width: 32px;
  height: 32px;
  background-color: #172B33;
  border-radius: 50%;
  margin-right: 12px;
}
.carousel__source-name {
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  font-style: normal;
  line-height: 143%;
  flex-shrink: 0;
}
.carousel__source-platform {
  font-family: "Inter", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  font-style: normal;
  line-height: 133%;
  flex-grow: 1;
}