@mixin btn($color, $background, $hover-color: '', $hover-background: '', $border: '') {
  @include subheading-style-2();

  display: block;
  text-align: center;
  text-decoration: none;
  padding: 16px 20px;
  color: $color;
  background-color: $background;
  border: 1px solid;
  transition: $transition-default;

  @if $border == '' {
    border-color: $background;
  }

  @else {
    border-color: $border;
  }

  &:hover {
    color: $hover-color;
    background-color: $hover-background;
    border-color: $hover-background;
  }
}

@mixin arrow-btn($color) {
  @include subheading-style-2();

  display: flex;
  align-items: center;
  padding: 5px 0;
  color: $color;
  text-decoration: none;
  transition: $transition-default;

  &:hover {
    svg {
      margin-right: 20px;
    }
  }

  svg {
    width: 18px;
    fill: $color;
    margin-right: 10px;
    transition: $transition-default;
  }

  span {
    margin-top: 3px;
  }
}