body {
  background-color: $cream;
}

img {
  width: 100%;
}

.object-cover {
  object-fit: cover;
  height: 100%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.icon-heading {
  z-index: 4;
  position: relative;

  svg {
    z-index: -1;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 50px;
    fill: $peach;
    transform: rotate(-15deg);

    @include breakpoint(medium) {
      top: -16px;
      width: 72px;
    }

    @include breakpoint(large) {
      right: -40px;
    }
  }
}