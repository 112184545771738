// Typography
/* ------------------------------------- */

// Headings
.heading-style-1 { @include heading-style-1() }

.heading-style-2 { @include heading-style-2() }

.heading-style-3 { @include heading-style-3() }

// Subheadings
.subheading-style-1 { @include subheading-style-1() }

.subheading-style-2 { @include subheading-style-2() }

// Paragraph
.paragraph-style-1 { @include paragraph-style-1(); }

.paragraph-style-2 { @include paragraph-style-2(); }

// .text-wrap {
//   &--paragraph-style-1 {
//     p, ul { @extend .paragraph-style-1; }

//     li {
//       margin-left: 10px;
//       list-style-type: disc;
//       list-style-position: outside;
//     }
//   }
// }

// Colors
.text-light { color: $cream; }

.text-dark { color: $charcoal; }

.text-primary { color: $brick; }

// Spacing Relationships
/* ------------------------------------- */
.heading-style-2 + .paragraph-style-1 { margin-top: 24px; }

.heading-style-3 + .paragraph-style-1 { margin-top: 24px; }

.heading-style-3 + .paragraph-style-2 { margin-top: 8px; }

.heading-style-3 + .button-group { margin-top: 32px }

.paragraph-style-1 + .button-group { margin-top: 32px; }

.paragraph-style-1 + .arrow-btn-group { margin-top: 50px; }

.paragraph-style-2 + .button-group { margin-top: 20px; }
