@mixin heading-style-1() {
  font-family: $font-primary;
  font-size: rem-calc(48px);
  font-weight: 600;
  font-style: normal;
  line-height: 100%;
  letter-spacing: -0.02em;

  @include breakpoint(medium) {
    font-size: rem-calc(60px);
  }
}

@mixin heading-style-2() {
  font-family: $font-primary;
  font-size: rem-calc(36px);
  font-weight: 600;
  font-style: normal;
  line-height: 100%;
  letter-spacing: -0.02em;

  @include breakpoint(medium) {
    font-size: rem-calc(48px);
  }
}

@mixin heading-style-3() {
  font-family: $font-primary;
  font-size: rem-calc(36px);
  font-weight: 600;
  font-style: normal;
  line-height: 111%;
  letter-spacing: -0.02em;

  @include breakpoint(medium) {
    font-size: rem-calc(36px);
  }
}

@mixin subheading-style-1() {
  font-family: $font-secondary;
  font-size: rem-calc(16px);
  font-weight: 700;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.25em;

  @include breakpoint(medium) {
    font-size: rem-calc(16px);
  }
}

@mixin subheading-style-2() {
  font-family: $font-secondary;
  font-size: rem-calc(12px);
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.25em;

  @include breakpoint(medium) {
    font-size: rem-calc(12px);
  }
}

@mixin paragraph-style-1() {
  font-family: $font-tertiary;
  font-size: rem-calc(16px);
  font-weight: 400;
  font-style: normal;
  line-height: 150%;

  @include breakpoint(medium) {
    font-size: rem-calc(18px);
    line-height: 155%;
  }
}

@mixin paragraph-style-2() {
  font-family: $font-tertiary;
  font-size: rem-calc(16px);
  font-weight: 400;
  font-style: normal;
  line-height: 150%;

  @include breakpoint(medium) {
    font-size: rem-calc(16px);
  }
}
