// Buttons
/* ------------------------------------- */

.btn {

  &--full-width {
    width: 100%;
  }
  
  &--solid-cream {
    @include btn($burgundy, $cream, $forest, $aqua);
  }

  &--solid-burgundy {
    @include btn($cream, $burgundy, $cream, $brick);
  }

  &--hollow-cream {
    @include btn($cream, transparent, $forest, $aqua, $cream); 
  }

  &--hollow-burgundy {
    @include btn($burgundy, transparent, $cream, $brick, $burgundy); 
  }
}

.arrow-btn-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start
}

.arrow-btn {
  
  &--burgundy {
    @include arrow-btn($burgundy);
  }

  + .arrow-btn {
    margin-top: 40px;
  }
}

// Button Group
/* ------------------------------------- */

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: rem-calc(16px);
}
