@import '../mixins/mixins-foundation';
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

// Colors
$white: #ffffff;
$black: #172B33;
$charcoal: #3f5661;
$cream: #f8f4df;
$wine: #4d1324;
$burgundy: #7c1e39;
$brick: #aa372c;
$peach: #db9279;
$jungle: #172B33;
$forest: #09403b;
$aqua: #75C9be;
$pewter: #9aa99e;

// Fonts
$font-primary: "new-spirit-condensed", serif;
$font-secondary: 'Hamburg', serif;
$font-tertiary: 'Inter', sans-serif;

// Grid
$grid-max-width: rem-calc(1400px);
$grid-column-gutter: (
  small: 20px,
  large: 40px,
);

// Easing
$ease-default: ease-in-out;

// Transitions
$transition-default: 0.2s $ease-default;